import React, { type ReactNode } from 'react'
// @ts-ignore: WIP imports
import { FullScreenPage } from '../layout'
import { MailtoSupportLink } from '../links'

export default function Fallback({
  error,
  children,
}: {
  error?: any
  children: ReactNode
}) {
  return (
    <Choose>
      <When condition={!error}>{children}</When>
      <Otherwise>
        <FullScreenPage>
          <div className='container tw-p-6'>
            <div
              className='tw-mb-6'
              css={{ fontSize: 58, fontWeight: 600, lineHeight: 1.12 }}
            >
              Whoops! Something went wrong.
            </div>
            <div
              className='tw-mb-6'
              css={{ fontSize: 32, fontWeight: 600, lineHeight: 1.35 }}
            >
              We've logged the error and routinely check our logs to resolve
              issues. You may try refreshing the page or clicking the back
              button in your browser. If that doesn't work and this issue is
              preventing you from accomplishing a task, please contact{' '}
              <MailtoSupportLink />. You can help us resolve the issue by
              describing the action you were taking when it occurred. Thank you!
            </div>
          </div>
        </FullScreenPage>
      </Otherwise>
    </Choose>
  )
}
