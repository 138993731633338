import DOMPurify from 'isomorphic-dompurify'

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // Set all elements with target to add rel="noopener noreferrer"
  if (node.getAttribute('target')) {
    const rel = node.getAttribute('rel')
    const rels = rel ? rel.split(' ') : []
    for (const value of ['noopener', 'noreferrer']) {
      if (!rels.includes(value)) rels.push(value)
    }
    node.setAttribute('rel', rels.join(' '))
  }
})

export function sanitizeHtml(html: string) {
  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['target'],
  })
}

export function sanitizeCKEditorHtml(html: string) {
  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['target', 'url'],
    ADD_TAGS: ['oembed'],
  })
}
