import React from 'react'
import { useActiveEditor, useActiveEditorManager } from '../active'
import { useEditorTransaction } from '../hooks'
import { ToolbarTools } from '../tools'
import { Toolbar, type ToolbarProps } from './toolbar'

export type ActiveEditorToolbarProps = ToolbarProps & {
  tools: string
}

export const ActiveEditorToolbar: React.FC<ActiveEditorToolbarProps> = ({
  tools,
  ...props
}) => {
  const manager = useActiveEditorManager()
  const editor = useActiveEditor(manager)
  useEditorTransaction(editor)
  return (
    <Toolbar
      {...props}
      ref={(el) => {
        manager.activeToolbarEl = el
      }}
    >
      <ToolbarTools editor={editor} tools={tools} />
    </Toolbar>
  )
}
