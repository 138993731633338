import { type History, createBrowserHistory } from 'history'
import qs from 'query-string'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// Create the history instance (browser-only)
export let history: History
if (typeof document !== 'undefined') {
  history = createBrowserHistory()

  // URL Changes
  history.listen((location, action) => {
    const { gtag, Intercom } = window as any

    // Google Analytics
    if (gtag && ['PUSH'].includes(action)) {
      gtag('send', 'pageview', location.pathname)
    }

    // Intercom
    if (Intercom && ['POP', 'PUSH'].includes(action)) {
      Intercom('update')
    }
  })
}

// Trim the hash value
export const trimHash = (hash: string) => {
  if (hash[0] === '#') hash = hash.substring(1)
  return hash
}

export type ReplaceHashOptions = {
  setQuery?: boolean
}

// Replace the current hash
export function replaceHash(hash?: string, options: ReplaceHashOptions = {}) {
  if (hash === window.location.hash) return

  // Update the hash always
  const location = { ...window.location, hash }

  // Update a query parameter to survive authentication redirects?
  if (options.setQuery) {
    const query = qs.parse(location.search)
    query.anchor = trimHash(hash)
    location.search = qs.stringify(query)
  }

  // njsscan-ignore: node_timing_attack
  history.replace(location)
}

// Update the current query params
export const patchQuery = (changes: Record<string, any>) => {
  const { location } = history
  const prevQuery = qs.parse(location.search)
  return setQuery({ ...prevQuery, ...changes })
}

// Set the current query params
export const setQuery = (query: Record<string, any> = {}) => {
  const { location } = history
  const search = qs.stringify(query)
  history.replace({ ...location, search })
}

/** Hook to transform the location's search to query parameters */
export function useQuery(): Record<string, string> {
  const location = useLocation()
  return useMemo(() => qs.parse(location.search), [location.search])
}

/** Hook for a query parameter's state */
export function useQueryParam(key: string, defaultValue?: string) {
  const query = useQuery()
  const value = key in query ? query[key] : defaultValue

  const setValue = useCallback(
    (newValue: string | undefined) => patchQuery({ [key]: newValue }),
    [key],
  )

  return [value, setValue] as const
}

const falseValue = '0'
const trueValue = '1'

/** Hook to store a boolean in a query parameter */
export function useBooleanQueryParam(key: string, defaultValue = false) {
  const [strValue, setStrValue] = useQueryParam(key)

  const value = useMemo(() => {
    if (strValue === undefined) return defaultValue
    return strValue === trueValue
  }, [defaultValue, strValue])

  const setValue = useCallback(
    (newValue: boolean | undefined) => {
      if (newValue === undefined) return setStrValue(undefined)
      return setStrValue(newValue ? trueValue : falseValue)
    },
    [setStrValue],
  )

  return [value, setValue] as const
}

/** Hook to store an integration in a query parameter */
export function useIntegerQueryParam(key: string, defaultValue?: number) {
  const [strValue, setStrValue] = useQueryParam(key)

  const value = useMemo(() => {
    if (strValue === undefined) return defaultValue
    return Number.parseInt(strValue, 10)
  }, [defaultValue, strValue])

  const setValue = useCallback(
    (newValue: number | undefined) => {
      if (newValue === undefined) return setStrValue(undefined)
      const newStrValue = newValue.toString()
      return setStrValue(newStrValue)
    },
    [setStrValue],
  )

  return [value, setValue] as const
}
