import { useEffect } from 'react'

export function useScrollLock(isEnabled = true) {
  const node = document.body
  return useEffect(() => {
    if (!node || !isEnabled) return

    // Disable scrolling
    node.classList.add('no-scroll')

    return () => {
      // Re-enable scrolling
      node.classList.remove('no-scroll')
    }
  }, [node, isEnabled])
}
