import type { HeadingNodeAttributes } from '@blissbook/lib/document'
import { HeadingNode } from '@blissbook/lib/document/schema/nodes'

export const HeadingEditorNode = HeadingNode.extend({
  addNodeView() {
    return ({ HTMLAttributes, node }) => {
      // attrs
      const attrs = node.attrs as HeadingNodeAttributes
      const { expression, id, indent, level } = attrs
      const { style } = HTMLAttributes

      const containerNode = document.createElement('div')
      containerNode.className = 'text-container'
      if (expression) containerNode.dataset.expression = expression
      if (indent) containerNode.classList.add(`bb-indent-${indent}`)

      const headingNode = document.createElement(`h${level}`)
      headingNode.id = id
      headingNode.setAttribute('style', style)
      containerNode.appendChild(headingNode)

      return {
        dom: containerNode,
        contentDOM: headingNode,
      }
    }
  },
})
