import { type Extensions, getSchema } from '@tiptap/core'
import isArray from 'lodash/isArray'
import {
  Node as ProseMirrorNode,
  type Schema as ProseMirrorSchema,
} from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'
import { DocumentBuilder } from '../builder'
import { htmlToJSON, jsonToHTML } from '../html'
import type { Node } from '../types'
import { BlissbookExtension, IdExtension } from './extensions'
import {
  ExpressionExtension,
  IndentExtension,
  TextAlignExtension,
} from './formats'
import {
  BoldMark,
  ColorMark,
  FontSizeMark,
  ItalicMark,
  LinkMark,
  UnderlineMark,
} from './marks'
import {
  BulletListNode,
  DocNode,
  HardBreakNode,
  HeadingNode,
  HorizontalRuleNode,
  ImageNode,
  ListItemNode,
  OrderedListNode,
  ParagraphNode,
  PdfNode,
  ReadMoreNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  TextNode,
  VariableNode,
  VideoNode,
} from './nodes'

export type DocumentSchemaOptions = {
  content: string
  extensions: Extensions
}

const getExtensions = ({ content, extensions }: DocumentSchemaOptions) => [
  // required extensions
  BlissbookExtension,
  IdExtension,
  // required nodes
  DocNode.extend({ content }),
  TextNode,
  // optional
  ...extensions,
]

const resolveExtensions = (extensions: Extensions | DocumentSchemaOptions) => {
  if (isArray(extensions)) return extensions
  return getExtensions(extensions)
}

export class DocumentSchema {
  readonly extensions: Extensions
  private _builder: DocumentBuilder
  private _proseMirrorSchema: ProseMirrorSchema

  constructor(extensions: Extensions | DocumentSchemaOptions) {
    this.extensions = resolveExtensions(extensions)
  }

  get builder() {
    this._builder ||= new DocumentBuilder(this.proseMirrorSchema)
    return this._builder
  }

  get proseMirrorSchema() {
    this._proseMirrorSchema ||= getSchema(this.extensions)
    return this._proseMirrorSchema
  }

  build(buildFn: (builder: DocumentBuilder) => Node[]) {
    return buildFn(this.builder)
  }

  reject(extensionNames: string[]) {
    const extensions = this.extensions.filter(
      (extension) => !extensionNames.includes(extension.name),
    )
    return new DocumentSchema(extensions)
  }

  htmlToJSON(html: string) {
    return htmlToJSON(html, this.proseMirrorSchema)
  }

  jsonToHTML(content: Node[]) {
    return jsonToHTML(content, this.proseMirrorSchema)
  }

  createProseMirrorState(doc: ProseMirrorNode) {
    const schema = this.proseMirrorSchema
    return EditorState.create({ doc, schema })
  }

  getProseMirrorStateFromContent(content: Node[]) {
    const schema = this.proseMirrorSchema
    const doc = ProseMirrorNode.fromJSON(schema, { type: 'doc', content })
    return this.createProseMirrorState(doc)
  }

  isContentValid(content: Node[]) {
    try {
      this.getProseMirrorStateFromContent(content)
      return true
    } catch (_error) {
      return false
    }
  }
}

const formats = [IndentExtension, TextAlignExtension]

const textFormatMarks = [
  BoldMark,
  ColorMark,
  FontSizeMark,
  ItalicMark,
  UnderlineMark,
]

const textMarks = [...textFormatMarks, LinkMark]

const embedNodes = [ImageNode, PdfNode, VideoNode]

const listNodes = [BulletListNode, ListItemNode, OrderedListNode]

const tableNodes = [TableNode, TableCellNode, TableRowNode]

const flatBodyNodes = [
  ...embedNodes,
  HardBreakNode,
  HeadingNode,
  HorizontalRuleNode,
  ParagraphNode,
  ...listNodes,
  ...tableNodes,
]

export const fullSchema = new DocumentSchema({
  content: '(block | readMore | table)+',
  extensions: [
    ExpressionExtension,
    ...formats,
    ...textMarks,
    // Nodes
    ...embedNodes,
    HardBreakNode,
    HeadingNode,
    HorizontalRuleNode,
    ...listNodes,
    ParagraphNode,
    ReadMoreNode,
    ...tableNodes,
    VariableNode,
  ],
})

export const titleSchema = new DocumentSchema({
  content: 'block+',
  extensions: [...textMarks, HardBreakNode, ParagraphNode],
})

export const bodySchema = new DocumentSchema({
  content: '(block | readMore | table)+',
  extensions: [
    ExpressionExtension,
    ...formats,
    ...textMarks,
    // Nodes
    ...flatBodyNodes,
    ReadMoreNode,
  ],
})

export const acknowledgementTitleSchema = new DocumentSchema({
  content: 'block+',
  extensions: [...textFormatMarks, HardBreakNode, ParagraphNode],
})

export const acknowledgementBodySchema = new DocumentSchema({
  content: '(block | table)+',
  extensions: [ExpressionExtension, ...formats, ...textMarks, ...flatBodyNodes],
})

export const flatBodySchema = new DocumentSchema({
  content: '(block | table)+',
  extensions: [...formats, ...textMarks, ...flatBodyNodes],
})

export const emailSchema = new DocumentSchema({
  content: 'block+',
  extensions: [
    ...formats,
    // Marks
    BoldMark,
    ItalicMark,
    LinkMark,
    UnderlineMark,
    // Nodes
    ...listNodes,
    HardBreakNode,
    HorizontalRuleNode,
    ParagraphNode,
    VariableNode,
  ],
})

export const helpSchema = new DocumentSchema({
  content: 'block+',
  extensions: [
    ...textMarks,
    // Nodes
    ...listNodes,
    HardBreakNode,
    ParagraphNode,
  ],
})

export const policySchema = new DocumentSchema({
  content: '(block | readMore | table)+',
  extensions: [
    ...formats,
    ...textMarks,
    // Nodes
    ...flatBodyNodes,
    ReadMoreNode,
  ],
})

export const textSchema = new DocumentSchema({
  content: 'inline*',
  extensions: [VariableNode],
})
