import { handbookPermissions } from '../handbook'
import { organizationPermissions } from '../organization'
import { teamPermissions } from '../team'
import type { Role } from '../types'
import { mapById } from '../util'

export type SuperUserRoleId =
  | 'superUser'
  | 'superBrander'
  | 'superAdmin'
  | 'superOwner'

export type SuperUserRole = Role & {
  id: SuperUserRoleId | null
  color: string
}

export const superAdminRole: SuperUserRole = {
  id: 'superAdmin',
  label: 'Super Admin',
  color: 'red',
  description: 'User can administer everything in Blissbook',
  permissionIds: [
    'blissbook.admin',
    'superUsers.view',
    ...organizationPermissions
      .map((p) => p.id)
      .filter((id) => !['people.export', 'people.notify'].includes(id)),
    ...teamPermissions.map((p) => p.id),
    ...handbookPermissions
      .map((p) => p.id)
      .filter(
        (id) =>
          !['handbook.notifyPeople', 'handbook.recordSignature'].includes(id),
      ),
  ],
}

export const superOwnerRole: SuperUserRole = {
  id: 'superOwner',
  label: 'Super Owner',
  color: 'red',
  description:
    'User can administer everything in Blissbook and modify super admins',
  permissionIds: [
    ...superAdminRole.permissionIds,
    'ckbox.superadmin',
    'handbook.draft.editCustomHtml',
    'people.export',
    'superUsers.edit',
  ],
}

export const superBranderRole: SuperUserRole = {
  id: 'superBrander',
  label: 'Super Brander',
  color: 'blurple',
  description: 'User can brand everything in Blissbook',
  permissionIds: [
    'documents.view',
    'handbook.query',
    'handbook.draft.brand',
    'handbook.draft.edit',
    'handbook.draft.editCss',
    'handbook.draft.view',
    'handbook.draft.preview',
    'handbook.history.view',
    'folders.view',
    'organization.brand',
    'organization.view',
    'people.query',
  ],
}

export const superUserRole: Role = {
  id: 'superUser',
  label: 'Super User',
  description: 'User can access all organizations in Blissbook',
  permissionIds: [
    'billing.view',
    'handbook.query',
    'handbook.draft.view',
    'handbook.draft.preview',
    'handbook.history.view',
    'handbook.report',
    'handbook.live.view',
    'handbook.sharing.view',
    'organization.view',
    'orgCollaborators.view',
    'people.query',
    'people.report',
    'people.view',
    'team.teammates.view',
  ],
}

export const superUserRoles = [
  superOwnerRole,
  superAdminRole,
  superBranderRole,
  superUserRole,
]

export const superUserRolesById = mapById(superUserRoles)

export function getSuperUserRole(roleId: SuperUserRoleId) {
  return superUserRolesById.get(roleId) || superUserRole
}
