import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { type CSSProperties, type ReactNode, useMemo } from 'react'
import { v4 as uuidV4 } from 'uuid'
import type { InputSize } from './types'

export type CheckboxVariant = 'circle' | 'filled'

export type CheckboxProps = {
  className?: string
  color?: string
  disabled?: boolean
  id?: string
  indeterminate?: boolean
  label?: ReactNode
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean,
  ) => void
  onChangeValue?: (value: boolean) => void
  onClick?: React.MouseEventHandler<HTMLInputElement>
  size?: InputSize
  style?: CSSProperties
  value?: boolean
  variant?: CheckboxVariant
}

export const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>(
  (
    {
      className,
      color,
      indeterminate,
      label,
      onChange,
      onChangeValue,
      onClick,
      size,
      style,
      value = false,
      variant = 'filled',
      ...props
    },
    ref,
  ) => {
    const { disabled } = props

    const id = useMemo(() => props.id || uuidV4(), [props.id])

    // Determine checkbox attributes based on the state
    const getCheckboxProps = () => {
      switch (value) {
        case true:
          return { checked: true, indeterminate: undefined }
        case false:
          return { checked: false, indeterminate: undefined }
        default:
          return { checked: false, indeterminate: 'true' }
      }
    }

    return (
      <div
        className={classnames(
          'form-check',
          color && `form-check-${color}`,
          size && `form-check-${size}`,
          className,
        )}
        onClick={onClick}
        onKeyUp={(e) => e.key === 'Enter' && onClick}
        ref={ref}
        style={style}
      >
        <input
          {...props}
          {...getCheckboxProps()}
          className='form-check-input tw-hidden'
          disabled={disabled}
          id={id}
          onChange={(event) => {
            const value = event.target.checked
            if (onChange) onChange(event, value)
            if (onChangeValue) onChangeValue(value)
          }}
          type='checkbox'
        />

        <label htmlFor={id}>
          <div
            className={classnames(
              'form-check-box',
              variant && `form-check-box-${variant}`,
            )}
            style={{ marginRight: label ? '.5em' : 0 }}
          >
            <FontAwesomeIcon
              className='form-check-icon'
              icon={value === null ? 'minus' : 'check'}
            />
          </div>

          {label && <div className='form-check-label'>{label}</div>}
        </label>
      </div>
    )
  },
)
