import type { Role } from '../types'
import { mapById } from '../util'
import { organizationPermissions } from './permissions'

// Api Key permissions
export const apiKeyRole: Role = {
  id: 'orgApiKey',
  label: 'API Key',
  description: 'Permissions granted to the api key',
  permissionIds: ['api.export'],
}

// Permissions granted to all orgCollaborators that have a role on any resource in Blissbook
export const orgCollaboratorRole: Role = {
  id: 'orgCollaborator',
  label: 'Org Collaborator',
  description: 'Permissions granted to users who have a role',
  permissionIds: ['organization.view', 'people.query'],
}

export type AdminRoleId =
  | 'billingAdmin'
  | 'brandingAdmin'
  | 'orgAdmin'
  | 'techAdmin'

export type AdminRole = Role & {
  id: AdminRoleId
  color: string
  homeUrl?: string
}

const rejectOrganizationPermissionIds = ['groups.edit']

export const orgAdminRole: AdminRole = {
  id: 'orgAdmin',
  label: 'Org Admin',
  description:
    'Can do everything: manage all organization settings, subscription details, and all members.',
  color: 'red',
  permissionIds: [
    ...organizationPermissions
      .map((p) => p.id)
      .filter((p) => !rejectOrganizationPermissionIds.includes(p)),
    'handbook.query',
    'team.teammates.view',
    'team.teammates.edit',
  ],
}

export const billingAdminRole: AdminRole = {
  id: 'billingAdmin',
  label: 'Billing',
  description: 'Can view subscription information.',
  color: 'sunshine',
  homeUrl: '/subscription',
  permissionIds: [...orgCollaboratorRole.permissionIds, 'billing.view'],
}

export const brandingAdminRole: AdminRole = {
  id: 'brandingAdmin',
  label: 'Branding',
  description:
    'Can edit Email Branding, Email Templates, and Audience Dashboard Branding.',
  color: 'blurple',
  permissionIds: [
    ...orgCollaboratorRole.permissionIds,
    'handbook.query',
    'handbook.draft.brand',
    'handbook.draft.edit',
    'handbook.draft.preview',
    'handbook.draft.view',
    'handbook.history.view',
    'organization.brand',
  ],
}

export const techAdminRole: AdminRole = {
  id: 'techAdmin',
  label: 'Tech',
  description:
    'Can edit all Organization Members. They can also edit the Custom Domain, Email Sending Domain, SSO, and people sync integrations.',
  color: 'aqua',
  homeUrl: '/organization',
  permissionIds: [
    ...orgCollaboratorRole.permissionIds,
    'handbook.query',
    'handbook.performTech',
    'integrations.edit',
    'people.edit',
    'people.export',
    'people.view',
  ],
}

export const adminRoles: AdminRole[] = [
  brandingAdminRole,
  billingAdminRole,
  techAdminRole,
  orgAdminRole,
]

export const adminRolesReverse = [...adminRoles].reverse()

export const adminRolesById = mapById(adminRoles)
