import {
  emptyUrl,
  formatHref,
  getHrefProtocol,
  isRelativeUrl,
} from '@blissbook/lib/sanitize'
import type { Editor } from '@blissbook/ui/editor'
// @ts-ignore: WIP imports
import { Button, SearchInput, Tooltip } from '@blissbook/ui/lib'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect, useState } from 'react'
import { useVirtualRangeElement } from '../../selection/util'
import { BubbleToolbar } from '../../toolbar'
import {
  type DocumentBookmark,
  bookmarkIcon,
  findBookmark,
  useBookmarkOptions,
} from './bookmark'
import type { LinkEditorState } from './state'

export type LinkToolbarProps = {
  editor: Editor
  state: LinkEditorState
}

export const LinkToolbar: React.FC<LinkToolbarProps> = ({ editor, state }) => {
  const { bookmarks } = editor
  const { range } = state
  const width = 300
  const virtualEl = useVirtualRangeElement(editor, range)

  const [isEditing, setEditing] = useState(!state.href)
  const [value, setValue] = useState(() => state.href || '')
  const [bookmark] = useState(() => findBookmark(bookmarks, state.href))
  const [inputEl, setInputEl] = useState<HTMLInputElement>()
  const searchOptions = useBookmarkOptions(bookmarks)

  const href = formatHref(value)
  const isValid = href !== emptyUrl
  const protocol = getHrefProtocol(href)

  let searchIcon: IconProp | boolean
  if (value && isRelativeUrl(value[0])) {
    searchIcon = bookmarkIcon
  } else if (protocol) {
    searchIcon = protocol.icon
  } else {
    searchIcon = false
  }

  function onCancel() {
    editor.chain().focus().hideLinkEditor().run()
  }

  function onSubmit(value?: string) {
    const isValid = formatHref(value) !== emptyUrl
    if (!isValid) return

    const href = formatHref(value)
    editor
      .chain()
      .focus()
      .setTextSelection(range)
      .setLink(href)
      .hideLinkEditor()
      .run()
  }

  useEffect(() => {
    if (inputEl && isEditing) {
      inputEl.select()
    }
  }, [inputEl, isEditing])

  const removeButton = (
    <Tooltip content='Remove Link' key='remove'>
      <Button
        className='btn-icon'
        onClick={() => {
          editor.chain().focus().unsetLink().hideLinkEditor().run()
        }}
      >
        <FontAwesomeIcon icon={['fad', 'unlink']} />
      </Button>
    </Tooltip>
  )

  const submitButton = (
    <Button
      className={`btn ${isValid ? 'btn-success' : 'btn-secondary'} tw-px-2`}
      key='submit'
      onClick={() => {
        onSubmit(value)
      }}
    >
      <FontAwesomeIcon icon='check' />
    </Button>
  )

  return (
    <BubbleToolbar
      className='tw-flex tw-gap-1.5 tw-items-center tw-p-1.5 tw-font-sans'
      editor={editor}
      refEl={virtualEl}
      offset={4}
      style={{ width }}
    >
      <SearchInput
        clearIcon={false}
        className='tw-flex-1'
        debounce={0}
        dropdownClassName='-tw-ml-1.5 tw-border-0 tw-rounded-t-none'
        dropdownOffset={4}
        dropdownWidth={width}
        getOptions={searchOptions}
        inputClassName={!isEditing ? 'tw-bg-gray-100' : undefined}
        inputRef={setInputEl}
        name='url'
        onChangeValue={setValue}
        onEnterKey={() => {
          onSubmit(value)
        }}
        onFocus={() => {
          setEditing(true)
        }}
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            event.preventDefault()
            onCancel()
          }
        }}
        onSelect={(option: DocumentBookmark) => {
          onSubmit(option.href)
        }}
        placeholder={
          bookmarks
            ? 'Enter url, email, or section title'
            : 'Enter url or email'
        }
        searchIcon={searchIcon}
        title={isEditing ? undefined : state.href}
        type='text'
        value={bookmark && !isEditing ? bookmark.label : value}
      />

      {isEditing ? submitButton : removeButton}
    </BubbleToolbar>
  )
}
