import { Portal } from '@blissbook/ui/lib'
import { mergeRefs } from '@blissbook/ui/util'
import { cx } from '@emotion/css'
import {
  type Instance,
  type VirtualElement,
  createPopper,
} from '@popperjs/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { useActiveEditorManager } from '../active'
import type { Editor } from '../editor'
import { Toolbar, type ToolbarProps } from './toolbar'

export function useBubbleToolbarPopper(
  refEl: Element | VirtualElement,
  popperEl: HTMLElement | null,
  options?: {
    editor?: Editor
    offset?: number
  },
) {
  const { editor, offset } = options
  const [popper, setPopper] = useState<Instance>()

  useEffect(() => {
    if (!popperEl) return

    const popper = createPopper(refEl, popperEl, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, offset],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
      ],
      placement: 'top',
      strategy: 'fixed',
    })
    setPopper(popper)

    return () => {
      setPopper(undefined)
      popper.destroy()
    }
  }, [refEl, popperEl])

  useEffect(() => {
    if (!editor || !popper) return

    function onResize() {
      popper.forceUpdate()
    }

    editor.on('resize', onResize)
    return () => {
      editor.off('resize', onResize)
    }
  }, [editor, popper])

  return popper
}

export type BubbleToolbarProps = ToolbarProps & {
  editor?: Editor
  offset: number
  refEl: Element | VirtualElement
}

export const BubbleToolbar = forwardRef<HTMLDivElement, BubbleToolbarProps>(
  ({ className, editor, offset, refEl, ...props }, ref) => {
    const manager = useActiveEditorManager()
    const [toolbarEl, setToolbarEl] = useState<HTMLDivElement | null>(null)

    useBubbleToolbarPopper(refEl, toolbarEl, { editor, offset })

    return (
      <Portal>
        <Toolbar
          {...props}
          className={cx('toolbar-bubble', className)}
          ref={mergeRefs([
            ref,
            setToolbarEl,
            (el) => {
              manager.bubbleToolbarEl = el
              return el
            },
          ])}
        />
      </Portal>
    )
  },
)
