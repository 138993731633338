import {
  type KeyboardShortcutCommand,
  Node,
  getNodeType,
  isNodeActive,
} from '@tiptap/core'
import { getActiveList, headings } from '../../types'
import { idAttributes } from './block'

const level = 3

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    heading: {
      setHeading: () => ReturnType
      toggleHeading: () => ReturnType
      unsetHeading: () => ReturnType
    }
  }
}

export const HeadingNode = Node.create({
  name: 'heading',

  content: 'inline*',

  group: 'block',

  defining: true,

  addAttributes() {
    return {
      ...idAttributes,
      level: {
        default: level,
        rendered: false,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'h1',
        attrs: { level },
      },
      {
        tag: 'h2',
        attrs: { level },
      },
      {
        tag: 'h3',
        attrs: { level },
      },
      {
        tag: 'h4',
        attrs: { level },
      },
      {
        tag: 'h5',
        attrs: { level },
      },
      {
        tag: 'h6',
        attrs: { level },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [`h${level}`, HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setHeading:
        () =>
        ({ chain, editor, state }) => {
          const headingType = getNodeType('heading', state.schema)
          const paragraphType = getNodeType('paragraph', state.schema)
          const isHeading = isNodeActive(state, headingType)
          const isHeadingLevel = isNodeActive(state, headingType, { level })
          if (isHeadingLevel) return false

          const commands = chain()
          const activeList = getActiveList(editor)
          if (activeList) {
            commands.liftListItem('listItem')
          }

          if (isHeading) {
            return commands.updateAttributes(headingType, { level }).run()
          }
          const { textAlign } = editor.getAttributes(paragraphType)
          return commands.setNode(headingType, { level, textAlign }).run()
        },

      toggleHeading:
        () =>
        ({ commands, editor, state }) => {
          const headingType = getNodeType('heading', state.schema)
          const paragraphType = getNodeType('paragraph', state.schema)
          const isHeading = isNodeActive(state, headingType)
          const isHeadingLevel = isNodeActive(state, headingType, { level })

          if (isHeadingLevel) {
            const { textAlign } = editor.getAttributes(headingType)
            return commands.setNode(paragraphType, { textAlign })
          }
          if (isHeading) {
            return commands.updateAttributes(headingType, { level })
          }
          const { textAlign } = editor.getAttributes(paragraphType)
          return commands.setNode(headingType, { level, textAlign })
        },

      unsetHeading:
        () =>
        ({ commands, editor, state }) => {
          const headingType = getNodeType('heading', state.schema)
          const paragraphType = getNodeType('paragraph', state.schema)
          const isHeading = isNodeActive(state, headingType)

          if (isHeading) {
            const { textAlign } = editor.getAttributes(headingType)
            return commands.setNode(paragraphType, { textAlign })
          }

          return false
        },
    }
  },

  addKeyboardShortcuts() {
    return headings.reduce(
      (shortcuts, heading) => {
        const { keyboardShortcut } = heading
        shortcuts[keyboardShortcut] = () => this.editor.commands.toggleHeading()
        return shortcuts
      },
      {} as Record<string, KeyboardShortcutCommand>,
    )
  },
})
