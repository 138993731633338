// note: always use the `${colors}-${shade}` keys here to allow js color usage to mirror tailwindcss

module.exports = {
  'aqua-link': '#54A8DD',
  'aqua-900': '#02688d',
  'aqua-800': '#017ca7',
  'aqua-700': '#1b92c6', // fka aqua-dark
  'aqua-500': '#28aae2', // fka aqua
  'aqua-300': '#93d4ef', // fka aqua-light
  'aqua-100': '#d4eef9', // fka aqua-white
  'aqua-50': '#e7f5fb',

  black: '#333',
  'black-faded': 'rgba(28, 28, 28, 0.70)',
  'black-disabled': 'rgba(28, 28, 28, 0.55)',

  'blurple-900': '#242456',
  'blurple-800': '#2d2d6c',
  'blurple-700': '#39398a', // fka blurple-dark
  'blurple-500': '#4e4ed8', // fka blurple
  'blurple-300': '#999ff5', // fka blurple-light
  'blurple-200': '#c7cbf9', // fka blurple-white
  'blurple-100': '#f1f2fe', // fka blurple-100
  'blurple-50': '#f6f7fe', // fka blurple-whiter

  'gray-50': '#f7f7f7',
  'gray-100': '#f2f2f2',
  'gray-200': '#ebebeb',
  'gray-300': '#dadada',
  'gray-400': '#bbbbbb',
  'gray-500': '#9a9a9a',
  'gray-600': '#727272',
  'gray-700': '#4f4f4f',
  'gray-800': '#333333',
  'gray-900': '#1c1c1c',

  'green-50': '#e7f3ee',
  'green-300': '#bbedd9',
  'green-500': '#2ccf94',
  'green-700': '#1ba171',
  'green-800': '#19865e',
  'green-900': '#0c6e4b',

  'purple-900': '#3e1556',
  'purple-800': '#4a1966',
  'purple-700': '#602184', // fka purple-dark
  'purple-500': '#9f26ce', // fka purple
  'purple-300': '#c97ee7', // fka purple-light
  'purple-100': '#eecff7', // fka purple-100
  'purple-50': '#f3e1fa',

  'red-900': '#8b1d1d',
  'red-800': '#b52626',
  'red-700': '#d63c3c', // fka red-dark
  'red-500': '#fd5d5d', // fka red
  'red-300': '#fe9090', // fka red-light
  'red-50': '#ffd7d7', // fka red-50

  'sunshine-900': '#9a5904',
  'sunshine-800': '#ca7d02',
  'sunshine-700': '#fc9d06', // fka sunshine-dark
  'sunshine-500': '#ffbe45', // fka sunshine
  'sunshine-300': '#ffda94', // fka sunshine-light
  'sunshine-100': '#fff2d9', // fka sunshine-white
  'sunshine-50': '#fff8eb',

  white: '#fff',
  'white-faded': 'rgba(255, 255, 255, 0.85)',
  'white-disabled': 'rgba(255, 255, 255, 0.65)',
}
