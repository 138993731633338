import { AspectRatio } from '@blissbook/lib/aspectRatio'
import {
  type VideoNodeAttributes,
  embedAlignsByType,
} from '@blissbook/lib/document'
import { VideoNode } from '@blissbook/lib/document/schema/nodes'
import { Video } from '@blissbook/ui/lib/video'
import { VideoPlayer } from '@blissbook/ui/lib/video/player'
import React from 'react'
import ReactDOM from 'react-dom'
import type { Editor } from '../../editor'
import { type Size, bindResize, resizeEdges } from '../resize'
import { wrapSelectableNodeView } from '../selection'
import { getNodeViewPos } from '../util'

export const VideoEditorNode = VideoNode.extend({
  addNodeView() {
    return (props) => {
      const { editor, getPos, node } = props
      const attrs = node.attrs as VideoNodeAttributes
      const { expression, height, src, width } = attrs
      const align = embedAlignsByType[attrs.align]

      const video = Video.fromUrl(src)
      const hasCover = !!video.coverUrl

      const dom = document.createElement('div')
      dom.classList.add('rw-embed')
      if (align) dom.classList.add(align.className)
      if (expression) dom.dataset.expression = expression

      // rw-video
      const videoEl = document.createElement('rw-video')
      videoEl.className = 'rw-embed-asset tw-h-full tw-w-full'
      dom.appendChild(videoEl)

      function renderVideo({ height, width }: Size, hidePlayer?: boolean) {
        dom.style.height = height + 'px'
        dom.style.width = width + 'px'

        const el = hidePlayer ? null : (
          <VideoPlayer
            className='tw-bg-gray-400'
            height={height}
            url={src}
            width={width}
          />
        )

        videoEl.classList.toggle('tw-bg-gray-400', hidePlayer)

        ReactDOM.render(el, videoEl)
      }

      // Initial render, wait until element is in the DOM before rendering non-YouTube videos
      if (hasCover) {
        renderVideo({ height, width })
      } else {
        renderVideo({ height, width }, true)
        setTimeout(() => {
          renderVideo({ height, width })
        })
      }

      bindResize(dom, {
        edges: resizeEdges,
        editor: editor as Editor,
        onSubmit(size) {
          const pos = getNodeViewPos(getPos)
          editor
            .chain()
            .updateNodeAttributes(pos, size)
            .setNodeSelection(pos)
            .run()
        },
        setSize(size, initialSize) {
          // Try the new size
          const aspectRatio = new AspectRatio(
            initialSize.width,
            initialSize.height,
          )
          size.height = aspectRatio.getHeightFromWidth(size.width)
          renderVideo(size, !hasCover)

          // Return the actual size
          size.width = dom.offsetWidth
          size.height = aspectRatio.getHeightFromWidth(size.width)
          renderVideo(size)
          return size
        },
        showSize: true,
      })

      return wrapSelectableNodeView(dom, props)
    }
  },
})
