import { bulletList, orderedList } from '@blissbook/lib/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ToolbarButton } from '../toolbar/button'
import { hasExtension } from '../util'
import type { ToolComponent } from './types'

export const BulletListTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    active={editor?.isActive('bulletList')}
    disabled={!hasExtension(editor, 'bulletList')}
    keyboardShortcut={bulletList.keyboardShortcut}
    label={bulletList.label}
    onClick={() =>
      editor.chain().focus().toggleList(bulletList.name, 'listItem').run()
    }
  >
    <FontAwesomeIcon icon={bulletList.icon} />
  </ToolbarButton>
)

export const OrderedListTool: ToolComponent = ({ editor, ...props }) => (
  <ToolbarButton
    {...props}
    active={editor?.isActive('orderedList')}
    disabled={!hasExtension(editor, 'orderedList')}
    keyboardShortcut={orderedList.keyboardShortcut}
    label={orderedList.label}
    onClick={() =>
      editor.chain().focus().toggleList(orderedList.name, 'listItem').run()
    }
  >
    <FontAwesomeIcon icon={orderedList.icon} />
  </ToolbarButton>
)
