import isString from 'lodash/isString'

export type SortDirection = 'asc' | 'desc'

export type SortOrder = {
  field: string
  direction: SortDirection
}

export function parseSortOrder(
  value: SortOrder | string,
): SortOrder | undefined {
  if (!value) return

  if (!isString(value)) {
    return {
      field: value.field,
      direction: value.direction,
    }
  }

  if (value[0] === '-') {
    return {
      field: value.slice(1),
      direction: 'desc',
    }
  }

  return {
    field: value,
    direction: 'asc',
  }
}

export function stringifySortOrder(order: SortOrder) {
  const { field, direction } = order
  return direction === 'desc' ? `-${field}` : field
}

export function toggleSortOrder(order: SortOrder): SortOrder {
  return {
    ...order,
    direction: order.direction === 'asc' ? 'desc' : 'asc',
  }
}
