import './header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { type SortOrder, parseSortOrder, toggleSortOrder } from './SortOrder'

export const SortHeader: React.FC<{
  className?: string
  columnOrder: SortOrder | string
  onChangeOrder: (order: SortOrder) => void
  order: SortOrder
}> = ({ children, className, columnOrder, onChangeOrder, order, ...props }) => {
  const _columnOrder = parseSortOrder(columnOrder)
  const isActive = _columnOrder.field === order?.field
  const toggleOrder = isActive ? toggleSortOrder(order) : _columnOrder

  return (
    <div
      {...props}
      className={classnames('sort-header', className)}
      onClick={() => onChangeOrder(toggleOrder)}
    >
      {children}
      <If condition={isActive}>
        <SortIcon className='tw-ml-1' desc={order.direction === 'desc'} />
      </If>
    </div>
  )
}

export const SortIcon: React.FC<{
  className?: string
  desc?: boolean
}> = ({ desc, ...props }) => (
  <FontAwesomeIcon
    {...props}
    css={{
      display: 'inline-block',
      transform: desc ? 'rotate(180deg)' : undefined,
      transition: 'all 160ms linear',
    }}
    icon='caret-down'
  />
)
