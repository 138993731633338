import { AspectRatio } from '@blissbook/lib/aspectRatio'
import {
  type ImageNodeAttributes,
  embedAlignsByType,
  embedShapesByType,
} from '@blissbook/lib/document'
import { ImageNode } from '@blissbook/lib/document/schema/nodes'
import type { Editor } from '../../editor'
import { bindResize, resizeEdges } from '../resize'
import { wrapSelectableNodeView } from '../selection'
import { getNodeViewPos } from '../util'

export const ImageEditorNode = ImageNode.extend({
  addNodeView() {
    return (props) => {
      const { editor, getPos, node } = props
      const attrs = node.attrs as ImageNodeAttributes
      const { src, width } = attrs
      const align = embedAlignsByType[attrs.align]
      const shape = embedShapesByType[attrs.shape]

      const dom = document.createElement('span')
      dom.className = 'rw-embed'
      if (align) dom.classList.add(align.className)
      if (width) dom.style.width = width + 'px'

      const imageEl = document.createElement('img')
      imageEl.className = 'rw-embed-asset tw-max-w-full'
      imageEl.src = src
      if (attrs.align === 'center') imageEl.classList.add(align.className)
      if (shape) imageEl.classList.add(shape.className)
      if (width) imageEl.classList.add('tw-w-full')
      dom.appendChild(imageEl)

      bindResize(dom, {
        edges: resizeEdges,
        editor: editor as Editor,
        onSubmit({ width }) {
          const pos = getNodeViewPos(getPos)
          editor
            .chain()
            .updateNodeAttributes(pos, { height: undefined, width })
            .setNodeSelection(pos)
            .run()
        },
        setSize(size, initialSize, edge) {
          if (edge.xFactor) {
            dom.style.width = size.width + 'px'
          } else if (edge.yFactor) {
            const aspectRatio = new AspectRatio(
              initialSize.width,
              initialSize.height,
            )
            size.width = aspectRatio.getWidthFromHeight(size.height)
            dom.style.width = size.width + 'px'
          }

          size.height = dom.offsetHeight
          size.width = dom.offsetWidth
          dom.style.width = size.width + 'px'
          return size
        },
        showSize: true,
      })

      return wrapSelectableNodeView(dom, props)
    }
  },
})
