import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  type CSSProperties,
  type ReactNode,
  forwardRef,
  useContext,
  useState,
} from 'react'
import { usePopper } from 'react-popper'
import * as Popper from './Popper'

export const Context = Popper.Context
export const Provider = Popper.Provider

// Dropdown.Caret

export const Caret: React.FC<{
  className?: string
  icon?: IconProp
  style?: CSSProperties
}> = ({ className, icon = ['fas', 'chevron-down'], ...props }) => (
  <FontAwesomeIcon
    {...props}
    className={cx('dropdown-caret', className)}
    icon={icon}
  />
)

// Dropdown.ToggleButton

export type ToggleButtonProps = Popper.ToggleButtonProps & {
  caret?: IconProp | false
}

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  ({ caret = ['fas', 'chevron-down'], children, className, ...props }, ref) => {
    const { isOpen } = useContext(Popper.Context)
    return (
      <Popper.ToggleButton
        {...props}
        className={cx('dropdown-toggle', { open: isOpen }, className)}
        ref={ref}
      >
        {children}

        {caret && (
          <Caret
            className={children ? 'tw-ml-1.5' : 'tw-ml-0 tw-transform-none'}
            icon={caret}
          />
        )}
      </Popper.ToggleButton>
    )
  },
)

// Dropdown.Menu

export const Menu: React.FC<Popper.MenuProps> = ({
  className,
  onClick,
  ...props
}) => {
  const { setOpen } = useContext(Popper.Context)
  return (
    <Popper.Menu
      {...props}
      className={cx('dropdown-menu dropdown-menu-context', className)}
      onClick={(event) => {
        if (onClick) onClick(event)

        if (!event.defaultPrevented) {
          // If the user clicks a dropdown-item, close the closest dropdown-menu-context.
          const [dropdownMenuNode] = $(event.currentTarget).closest(
            '.dropdown-menu-context',
          )
          const [itemNode] = $(event.target).closest('.dropdown-item')
          const [itemDropdownMenuNode] = $(itemNode).closest(
            '.dropdown-menu-context',
          )
          const isItem = !!itemNode && itemDropdownMenuNode === dropdownMenuNode
          if (isItem) setOpen(false)
        }
      }}
    />
  )
}

// Dropdown.Divider

type DividerProps = React.HTMLAttributes<HTMLDivElement> & {}

export const Divider = forwardRef<HTMLDivElement, DividerProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={cx('dropdown-divider', className)} ref={ref} />
  ),
)

// Dropdown.Item

type ItemProps = React.HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  checked?: boolean
  description?: string
  Icon?: (props: { className: string }) => JSX.Element
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    { active, checked, children, className, description, Icon, ...props },
    ref,
  ) => {
    // Prepend an icon?
    if (Icon) {
      children = (
        <div>
          <Icon
            className={cx(
              'dropdown-item-icon',
              !active && 'tw-text-blurple-500',
            )}
          />

          {children}
        </div>
      )
    }

    // Add a description?
    if (description) {
      children = (
        <div>
          {children}

          <div
            className={cx(
              'tw-font-normal tw-text-xs tw-ml-6 tw-text-black-faded tw-whitespace-normal tw-w-44 tw-line-clamp-2',
              active && 'tw-text-white',
            )}
          >
            {description}
          </div>
        </div>
      )
    }

    return (
      <div
        {...props}
        className={cx(
          'dropdown-item',
          checked && 'tw-flex tw-items-center tw-justify-between',
          { active },
          className,
        )}
        ref={ref}
      >
        {children}

        {checked && (
          <FontAwesomeIcon
            className='dropdown-item-icon dropdown-item-icon-check'
            icon='check'
          />
        )}
      </div>
    )
  },
)

// Dropdown.Submenu

type SubmenuProps = React.HTMLAttributes<HTMLDivElement> & {
  item: ReactNode
  maxHeight?: number
}

export const Submenu = forwardRef<HTMLDivElement, SubmenuProps>(
  ({ children, className, item, maxHeight }, ref) => {
    const { isOpen } = useContext(Popper.Context)
    const [itemNode, setItemNode] = useState<HTMLElement | null>()
    const [menuNode, setMenuNode] = useState<HTMLElement | null>()

    const popper = usePopper(itemNode, menuNode, {
      placement: 'right-start',
    })

    return (
      <div className='dropdown-submenu' ref={ref}>
        <div className='dropdown-item' ref={setItemNode}>
          {item}

          <FontAwesomeIcon
            className='dropdown-submenu-arrow'
            css={{ fontSize: '.75em' }}
            icon={['far', 'chevron-right']}
          />
        </div>

        {isOpen && (
          <div
            {...popper.attributes.popper}
            className={cx('popper-menu dropdown-menu', className)}
            ref={setMenuNode}
            style={{
              ...popper.styles.popper,
              maxHeight,
              overflow: maxHeight ? 'auto' : undefined,
            }}
          >
            {children}
          </div>
        )}
      </div>
    )
  },
)
