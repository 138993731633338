import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ToolbarButton } from '../toolbar/button'
import { hasExtension } from '../util'
import type { ToolComponentProps } from './types'

export const IndentLessTool: React.FC<ToolComponentProps> = ({
  editor,
  ...props
}) => (
  <ToolbarButton
    {...props}
    disabled={!hasExtension(editor, 'indent')}
    label='Indent Less'
    onClick={() => {
      editor.chain().focus().adjustIndent(-1).run()
    }}
  >
    <FontAwesomeIcon icon='outdent' />
  </ToolbarButton>
)

export const IndentMoreTool: React.FC<ToolComponentProps> = ({
  editor,
  ...props
}) => (
  <ToolbarButton
    {...props}
    disabled={!hasExtension(editor, 'indent')}
    label='Indent More'
    onClick={() => {
      editor.chain().focus().adjustIndent(1).run()
    }}
  >
    <FontAwesomeIcon icon='indent' />
  </ToolbarButton>
)

export const IndentTool: React.FC<ToolComponentProps> = (props) => (
  <>
    <IndentLessTool {...props} />
    <IndentMoreTool {...props} />
  </>
)
