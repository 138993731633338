import { Node } from '@tiptap/core'
import type { PdfNodeAttributes } from '../../types'
import { embedAttributes } from './embed'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pdf: {
      insertPdf: (attrs: PdfNodeAttributes) => ReturnType
    }
  }
}

export const PdfNode = Node.create({
  name: 'pdf',
  draggable: true,
  group: 'block',

  addAttributes() {
    return embedAttributes
  },

  parseHTML() {
    return [
      {
        tag: 'rw-pdf[src]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['rw-pdf', HTMLAttributes]
  },

  addCommands() {
    return {
      insertPdf:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent(
            {
              type: this.name,
              attrs: {
                align: 'center',
                height: 500,
                width: 770,
                ...attrs,
              },
            },
            {
              updateSelection: true,
            },
          )
        },
    }
  },
})
