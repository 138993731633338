import { useQueryParam } from '@blissbook/ui/util/history'
import { useCallback, useMemo, useState } from 'react'
import { type SortOrder, parseSortOrder, stringifySortOrder } from './SortOrder'

/** Hook to maintain sort order within memory */
export function useSortOrderState(defaultValue?: SortOrder | string) {
  return useState(() => parseSortOrder(defaultValue))
}

/** Hook to maintain sort order in the URL */
export function useSortOrderLocationState(defaultValue: SortOrder | string) {
  const [value, setValue] = useQueryParam('order')

  const order = useMemo(
    () => parseSortOrder(value || defaultValue),
    [value, defaultValue],
  )

  const setOrder = useCallback(
    (newOrder: SortOrder) => {
      const newValue = stringifySortOrder(newOrder)
      setValue(newValue)
    },
    [setValue],
  )

  return [order, setOrder] as const
}
