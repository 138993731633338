import { Shape } from '@blissbook/ui/lib/shape'
import React from 'react'

const defaultAlignX = 'left'
const defaultAlignY = 'top'

export const gridShapeSize = 120

export const ShapeGrid = React.forwardRef(
  (
    {
      alignX = defaultAlignX, // left or right
      alignY = defaultAlignY, // top or bottom
      shapeDefaults,
      shapeSize = gridShapeSize,
      shapes,
      ...props
    },
    ref,
  ) => (
    <div css={{ position: 'relative' }} ref={ref} {...props}>
      {shapes.map((shape, index) => {
        const { x, y, style, ...shapeProps } = shape
        return (
          <Shape
            {...shapeDefaults}
            {...shapeProps}
            size={shapeSize}
            key={index}
            css={{ position: 'absolute' }}
            style={{
              ...style,
              [alignY]: y * shapeSize,
              [alignX]: x * shapeSize,
            }}
          />
        )
      })}
    </div>
  ),
)

export const BackgroundShapeGrid = (props) => {
  const { alignX = defaultAlignX, alignY = defaultAlignY } = props

  return (
    <ShapeGrid
      {...props}
      css={{
        position: 'absolute',
        [alignX]: 0,
        [alignY]: 0,
        zIndex: -1,
      }}
    />
  )
}
