import { ApolloError } from '@apollo/client'
import config from '@blissbook/ui-config'
import { renderSentence } from '@blissbook/ui/util/grammar'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import isString from 'lodash/isString'
import React from 'react'
import { Toast, addToast } from './toaster'

const unknownErrorMessage = `Whoops! Something went wrong. We've logged the error and routinely check our logs to resolve issues. You may try refreshing the page and perform the same operation. If that doesn't work and this issue is preventing you from accomplishing a task, please contact support.`

// Format an apollo error message
const getApolloErrorResponse = (error) => {
  const { networkError } = error
  const [graphQLError] = error.graphQLErrors
  if (graphQLError) {
    const { extensions, message } = graphQLError
    const statusCode = extensions?.statusCode
    return { message, statusCode }
  }
  if (networkError) {
    if (!isString(networkError.result)) return
    return {
      message: networkError.result,
      statusCode: networkError.statusCode,
    }
  }
}

// Format an axios error message
const getAxiosErrorResponse = (error) => {
  const { response } = error
  const { message, statusCode } = response.data
  return { message, statusCode }
}

// Get the response from the error
export const getErrorResponse = (error) => {
  if (error instanceof ApolloError) return getApolloErrorResponse(error)
  if (error.response) return getAxiosErrorResponse(error)
}

// Format the error message from this error
export function formatError(error) {
  // Let strings pass through
  if (isString(error)) return error

  // Check if this is an API response
  const res = getErrorResponse(error)
  if (res) {
    const { message, statusCode } = res
    const isKnown = statusCode && statusCode < 500
    return isKnown ? message : unknownErrorMessage
  }
}

// Render the error message in a sentence
export function formatErrorSentence(error) {
  const message = formatError(error)
  return renderSentence(message)
}

// Render the error toast
const renderErrorToast = (message, title) => (
  <Toast message={message} title={title} />
)

// Add an error toaster
export const addErrorToast = (message, title) => {
  const el = renderErrorToast(message, title)
  addToast(el, {
    autoClose: message ? 4000 : 10000,
    type: 'error',
  })
}

// Handle an error message
export const handleError = (error, title) => {
  // Prevent the error from being handleded twice (e.g. rethrow)
  if (error.handled) return
  error.handled = true

  // Log to console
  if (!config.env.production) console.error(error)

  // If we don't know what it is, log to Sentry
  const message = formatError(error)
  if (!message) logUIError(error)

  // Toast the message (if desired)
  if (title !== false) {
    addErrorToast(message, title)
  }
}
