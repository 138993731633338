import { colors } from '@blissbook/ui/branding'
import { mergeRefs } from '@blissbook/ui/util'
import { cx } from '@emotion/css'
import {
  FloatingPortal,
  type UseFloatingReturn,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions'
import type { Placement } from '@floating-ui/react-dom-interactions'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useRef, useState } from 'react'
import './Tooltip.scss'

export type TooltipTheme = 'expression' | 'infobox'

type TooltipThemeStyle = {
  backgroundColor: string
  borderColor: string
  color: string
}

const defaultThemeStyle: TooltipThemeStyle = {
  backgroundColor: colors.black,
  borderColor: colors.black,
  color: colors.white,
}

const themeStyles: Record<TooltipTheme, TooltipThemeStyle> = {
  expression: {
    backgroundColor: colors.white,
    borderColor: colors['gray-300'],
    color: colors.black,
  },
  infobox: {
    backgroundColor: colors.white,
    borderColor: colors['gray-500'],
    color: colors.black,
  },
}

function getThemeStyle(theme?: TooltipTheme) {
  return themeStyles[theme] || defaultThemeStyle
}

export type TooltipProps = {
  className?: string
  children: React.ReactNode
  content: React.ReactNode
  disabled?: boolean
  duration?: number
  initialOpen?: boolean
  maxWidth?: number
  onOpenChange?: (open: boolean) => void
  open?: boolean
  placement?: Placement
  theme?: TooltipTheme
}

function useTooltipInteractions(state: UseFloatingReturn) {
  const hover = useHover(state.context, {
    move: false,
    enabled: true,
  })

  const focus = useFocus(state.context, {
    enabled: true,
  })

  const dismiss = useDismiss(state.context)

  const role = useRole(state.context, {
    role: 'tooltip',
  })

  return useInteractions([hover, focus, dismiss, role])
}

const arrowSize = 7

// https://floating-ui.com/docs/arrow
function getArrowStyle({
  placement,
  x,
  y,
}: {
  placement: Placement
  x?: number
  y?: number
}) {
  const [side] = placement.split('-')

  const mainSide = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  }[side]

  const rotation = {
    top: 45,
    bottom: 180 + 45,
    left: 270 + 45,
    right: 90 + 45,
  }[side]

  return {
    height: arrowSize,
    left: x,
    top: y,
    width: arrowSize,
    [mainSide]: -arrowSize / 2,
    transform: `rotate(${rotation}deg)`,
  }
}

// https://floating-ui.com/docs/react-dom
export function Tooltip({
  className,
  children,
  content,
  disabled,
  duration = 0.2,
  initialOpen = false,
  maxWidth,
  placement = 'top',
  theme,
}: TooltipProps) {
  const arrowRef = useRef()
  const [open, setOpen] = useState(initialOpen)

  const { backgroundColor, borderColor, color } = getThemeStyle(theme)

  const state = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip(),
      shift({ padding: 5 }),
      arrow({ element: arrowRef, padding: 5 }),
    ],
  })

  const interactions = useTooltipInteractions(state)

  const trigger = useMemo(() => {
    if (!React.isValidElement(children)) return null

    const { ref } = children as any
    return React.cloneElement(
      children,
      interactions.getReferenceProps({
        ref: mergeRefs([ref, state.reference]),
      }),
    )
  }, [children, interactions, state.reference])

  return (
    <>
      {trigger}

      <FloatingPortal id='floating-ui'>
        <AnimatePresence>
          {!disabled && open && (
            <motion.div
              animate={{ opacity: 1 }}
              className={cx('tooltip', theme && `tooltip-${theme}`, className)}
              data-placement={placement}
              initial={{ opacity: 0 }}
              ref={state.floating}
              style={{
                position: state.strategy,
                top: state.y ?? 0,
                left: state.x ?? 0,
              }}
              transition={{ duration }}
              {...interactions.getFloatingProps()}
            >
              <div
                className='tw-absolute'
                ref={arrowRef}
                style={getArrowStyle({
                  ...state.middlewareData.arrow,
                  placement: state.placement,
                })}
              >
                <svg
                  className='tw-absolute tw-left-0 tw-top-0'
                  width={arrowSize}
                  height={arrowSize}
                  viewBox={`0 0 ${arrowSize} ${arrowSize}`}
                  role='presentation'
                >
                  <polygon
                    fill={backgroundColor}
                    points={`0,0 0,${arrowSize} ${arrowSize},${arrowSize} ${arrowSize},0`}
                  />

                  <path
                    d={`M${arrowSize} 0 L${arrowSize} ${arrowSize} L0 ${arrowSize}`}
                    fill='none'
                    stroke={borderColor}
                    strokeWidth={1.5}
                  />
                </svg>
              </div>
              <div
                className='tooltip-content'
                style={{
                  backgroundColor,
                  borderColor,
                  color,
                  maxWidth,
                }}
              >
                {content}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}
