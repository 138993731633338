import type { AudienceRootExpression } from '@blissbook/lib/expression'
import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import keyBy from 'lodash/keyBy'

// EmbedAlign -----------------------------------------------------------------

export type EmbedAlignType = 'left' | 'center' | 'right'

export type EmbedAlign = {
  type: EmbedAlignType
  label: string
  className: string
  icon: IconProp
}

export const embedAligns: EmbedAlign[] = [
  {
    type: 'left',
    label: 'Align Left',
    className: 'pull-left',
    icon: ['fas', 'objects-align-left'],
  },
  {
    type: 'center',
    label: 'Align Center',
    className: 'pull-center',
    icon: ['fas', 'objects-align-center-horizontal'],
  },
  {
    type: 'right',
    label: 'Align Right',
    className: 'pull-right',
    icon: ['fas', 'objects-align-right'],
  },
]

export const embedAlignsByType = keyBy(embedAligns, 'type')

// EmbedAlign -----------------------------------------------------------------

export type EmbedShapeType = 'circle' | 'rounded'

export type EmbedShape = {
  type: EmbedShapeType
  label: string
  className: string
  icon: IconProp
}

export const embedShapes: EmbedShape[] = [
  {
    type: 'rounded',
    label: 'Rounded',
    className: 'img-rounded',
    icon: ['far', 'square'],
  },
  {
    type: 'circle',
    label: 'Circle',
    className: 'img-circle',
    icon: ['far', 'circle'],
  },
]

export const embedShapesByType = keyBy(embedShapes, 'type')

export type EmbedNodeAttributes = {
  align?: EmbedAlignType
  height?: number
  shape?: EmbedShapeType
  src: string
  width?: number
}

export type BlockEmbedNodeAttributes = EmbedNodeAttributes & {
  audienceExpression?: AudienceRootExpression
  expression?: string
}

export const getEmbedRenderProps = (attrs: EmbedNodeAttributes) => {
  const { height, width } = attrs
  const align = embedAlignsByType[attrs.align]
  const shape = embedShapesByType[attrs.shape]

  return {
    className: cx('rw-embed', align?.className, shape?.className),
    style: { height, width },
  }
}
