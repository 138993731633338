import { useLayoutEffect, useState } from 'react'

/** Hook to get the scrollOffset for the given node */
export function useScrollOffset(node: HTMLElement | null) {
  const [offset, setOffset] = useState({ x: 0, y: 0 })

  useLayoutEffect(() => {
    if (!node) return setOffset({ x: 0, y: 0 })

    function handleScroll() {
      setOffset({
        x: node.scrollLeft,
        y: node.scrollTop,
      })
    }

    node.addEventListener('scroll', handleScroll)

    return () => {
      node.removeEventListener('scroll', handleScroll)
    }
  }, [node])

  return offset
}
