import React from 'react'

import { logUIError } from '@blissbook/ui/util/integrations/sentry'

import * as Sentry from '@sentry/react'
import Fallback from './Fallback'

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const handleError = (event: ErrorEvent) => {
    const { error } = event
    logUIError(error)
  }

  const handleOnMount = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('error', handleError)
    }
  }

  const handleOnUnmount = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('error', handleError)
    }
  }
  return (
    <>
      <Sentry.ErrorBoundary
        fallback={({ error }) => <Fallback error={error}>{children}</Fallback>}
        onError={(error) => logUIError(error)}
        onMount={handleOnMount}
        onUnmount={handleOnUnmount}
      >
        {children}
      </Sentry.ErrorBoundary>
    </>
  )
}
